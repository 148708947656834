export const objects = [
  {
    id: "produit-bio",
    title: "Le produit bio",
    room: "cuisine",
    scenario: ["engage-cause"],
    content: [
      {
        type: "text",
        text: "Bravo, vous avez trouvé le produit bio ! Consommer différemment, c'est possible ! Découvrez les Avantages Engagés de Macif Avantages et profitez de réductions jusqu'à 50% sur nos partenaires tels que La Ruche qui dit Oui ! FairMoove, Label Emmaüs, Azureva...",
      },
      {
        type: "ressource",
        text: "Macif Avantages - offres engagées",
        label: "Macif Avantages - offres engagées",
        url: "https://www.macifavantages.fr/les-avantages-engages/#category=macif-all&filters=is-offre-engagee&page=1",
        cg: "Offres Macif Avantages soumises à conditions et non rétroactives, réservées aux sociétaires de la Macif, hors personnes morales. Plus d'informations sur nos Avantages Engagés (ex. : Label Emmaüs, Recommerce) sur www.macifavantages.fr/decouvrir-les avantages-engages. Les offres Macif Avantages sont proposées par la société M.A&S, SAS au capital de 37 000 €. RCS Niort 509 462 636. Siège social : 1 rue Jacques Vandier 79000 Niort. Immatriculée au Registre des Opérateurs de Voyages et de Séjours Atout France, sous le numéro IM079110011."
      }
    ],
    redirectObject: {
      room: "bureau",
      id: "ordinateur-produit-bio",
      title: "Ordinateur",
    },
  },
  {
    id: "ordinateur-produit-bio",
    title: "Ordinateur",
    room: "bureau",
    scenario: ["engage-cause"],
    content: [
      {
        type: "text",
        text: "Voilà, vous serez mieux sur grand écran :",
      },
      {
        type: "youtube",
        urlIframe:
          "https://www.youtube.com/embed/uioPckVvYAw?si=YfZ8EUGB6erREsnR",
      },
      {
        type: "ressource",
        text: "Vidéo - Protéger les océans",
        label: "Vidéo - Protéger les océans",
        url: "https://www.youtube.com/watch?v=uioPckVvYAw",
      },
    ],
  },
  {
    id: "poubelle",
    title: "La poubelle",
    room: "cuisine",
    scenario: ["engage-cause"],
    content: [
      {
        type: "text",
        text: `🤗 vous avez trouvé la poubelle ! Vous faites une activité sportive ou vous voulez vous engager comme bénévole dans une association ? Super ! <br>Sachez que si vous avez un contrat Garantie Accident, vous êtes couvert pour les accidents de la vie privée (accidents domestiques, de sport, de vacances ou encore de loisirs). Retrouvez tout ce qu'il y a à savoir sur la garantie accident en fin d'expérience !`,
      },
      {
        type: "ressource",
        text: "Contrat GA - FAQ macif.fr",
        label: "Contrat GA - FAQ macif.fr",
        url: "https://www.macif.fr/assurance/particuliers/faq?question=a-quoi-sert-contrat-garantie-accident#",
        cg: "Les garanties sont accordées dans les conditions et limites du contrat souscrit"
      },
    ],
  },
  {
    id: "cle",
    title: "Les clés",
    room: "garage",
    scenario: ["engage-cause"],
    content: [
      {
        type: "text",
        text: "Bravo, vous avez trouvé les clés de voiture ! Vous souhaitez vous lancer dans le covoiturage mais sans trop savoir par où commencer ? Découvrez Mobicoop, le partenaire de co-voiturage que la Macif vous propose : pas de commission sur les trajets ! Mettez l'article dans votre panier pour y revenir après le jeu",
      },
      {
        type: "ressource",
        text: "Page - Mobicoop, Le covoiturage libre",
        label: "Page - Mobicoop, Le covoiturage libre",
        url: "https://www.macif.fr/assurance/particuliers/assurance-auto-moto-scooter/assurance-automobile/covoiturage-et-location-entre-particuliers",
      },
    ],
  },
  {
    id: "plante",
    title: "La plante",
    room: "garage",
    scenario: ["engage-cause"],
    content: [
      {
        type: "text",
        text: "😀 Connaissez-vous l'investissement socialement responsable (ISR) qui permet de concilier la performance financière et le développement durable ?<br> L’investissement socialement responsable encourage les entreprises à adopter des pratiques plus vertueuses et responsables. Retrouvez toutes les infos en fin d'expérience pour en savoir plus.",
      },
      {
        type: "ressource",
        text: "Article - Épargne responsable",
        label: "Article - Épargne responsable",
        url: "https://www.macif.fr/assurance/particuliers/banque-credit-epargne/epargne-assurance-vie/fonds-isr#:~:text=La%20Macif%20propose%20des%20fonds,participer%20%C3%A0%20une%20finance%20durable",
      },
    ],
  },
  {
    id: "canette",
    title: "La canette",
    room: "cuisine",
    scenario: ["soin-proches"],
    content: [
      {
        type: "text",
        text: "Quand les enfants deviennent des ados, les rapports changent et ce n'est pas toujours évident de savoir comment leur parler. Y a-t-il une solution miracle ? Réponses de parents dans cet article.",
      },
      {
        type: "ressource",
        text: "Article - Parler aux ados",
        label: "Article - Parler aux ados",
        url: "https://vousparmacif.macif.fr/choses-faire-ni-dire-ado",
      },
    ],
  },
  {
    id: "berceau",
    title: "Le berceau",
    room: "chambre",
    scenario: ["soin-proches"],
    content: [
      {
        type: "text",
        text: "Bravo !<br>Devenir parent c'est une grande aventure qui met parfois le sommeil à rude épreuve. Mais ce n’est pas une fatalité et des astuces existent.<br>Direction le bureau pour consulter 1mn30 de vidéo.",
      },
    ],
    redirectObject: {
      room: "bureau",
      id: "ordinateur-berceau",
      title: "Ordinateur",
    },
  },
  {
    id: "ordinateur-berceau",
    title: "Ordinateur",
    room: "bureau",
    scenario: ["soin-proches"],
    content: [
      {
        type: "text",
        text: "Voilà, vous serez mieux sur grand écran :",
      },
      {
        type: "youtube",
        urlIframe: "https://www.youtube.com/embed/a3s35v3r40E",
      },
      {
        type: "text",
        text: "Ça vous a plu ? Alors mettez cette vidéo dans votre panier pour pouvoir la retrouver en fin d'expérience.",
      },
      {
        type: "ressource",
        text: "Vidéo - La parentalité à l'honneur",
        label: "Vidéo - La parentalité à l'honneur",
        url: "https://www.macif.fr/assurance/particuliers/les-moments-cles/la-famille-sagrandit/la-parentalite-a-lhonneur",
      },
    ],
  },
  {
    id: "trousse-soin",
    title: "Trousse de soin",
    room: "chambre",
    scenario: ["soin-proches"],
    content: [
      {
        type: "text",
        text: "👍 Lancez vous dans vos projets en gardant en tête qu'on n'est jamais à l'abri d'un aléa et de ses conséquences parfois lourdes sur le quotidien. Une protection adaptée est indispensable ! Réalisez votre diagnostic pour connaitre vos besoins.",
      },
      {
        type: "ressource",
        text: "Page - Diagnostic Prévoyance ",
        label: "Page - Diagnostic Prévoyance ",
        url: "https://www.macif.fr/assurance/particuliers/complementaire-sante-prevoyance/mon-diagnostic-prevoyance",
        cg: `Les solutions "Prévoyance" proposées dans le cadre de ce diagnostic sont communiquées à titre d’information. Ce diagnostic est établi à partir des informations communiquées, liées à votre situation personnelle. Elles ne prennent pas en compte les éventuels contrats d’assurance souscrits par ailleurs. Nous vous alertons sur le fait que les solutions assurantielles proposées, dans ce diagnostic, sont susceptibles d’évoluer en fonction de vos besoins. Pour une étude plus approfondie, nous vous invitons à prendre contact avec un conseiller Macif`,
      },
    ],
  },
  {
    id: "canape-animal",
    title: "Le panier",
    room: "chambre",
    scenario: ["soin-proches"],
    content: [
      {
        type: "text",
        text: "😊 Avoir un animal c’est super mais ça peut occasionner des dépenses imprévues comme les frais vétérinaire parfois élevés en cas d’accident ou de maladie. Savez-vous qu'il existe une assurance santé pour vous éviter de devoir assumer l’intégralité de ces frais ? Le plus intéressant étant de la souscrire quand votre animal est jeune.",
      },
      {
        type: "ressource",
        text: "Offre - Assurance chien chat",
        label: "Offre - Assurance chien chat",
        url: "https://www.macif.fr/assurance/particuliers/actualites/le-groupe-macif/nouveau-macif-assurance-chien-chat",
        cg: "Contrat accessible aux sociétaires Macif. Le contrat Macif Assurance Chien chat assuré par Macif est distribué par INTER MUTUELLES ASSISTANCE TECHNOLOGIES, SAS au capital de 500.000 €, RCS NANTES 353 259 922, Siège social : 31 Route de Gachet CS 60833 44308 NANTES cedex 3.",
      },
    ],
  },
  {
    id: "blocknote",
    title: "Le bloc note",
    room: "chambre",
    scenario: ["change-cap"],
    content: [
      {
        type: "text",
        text: "Rien ne vous échappe ! Qui dit nouveau projet, dit formalités et questionnements. Avec la Macif, vous avez accès gratuitement à une plateforme d'informations juridiques en ligne pour vous renseigner sur vos droits ou télécharger des courriers types (résiliation d’abonnements, contrat de location, achats en ligne…) rédigés par des juristes.",
      },
      {
        type: "ressource",
        text: "Connexion - Plateforme MACIFJuris",
        label: "Connexion - Plateforme MACIFJuris",
        url: "https://www.macif.fr/assurance/particuliers/assurance-habitation-et-vie-quotidienne/protection-juridique/plateforme-juridique-gratuite",
        cg: "L’accès à la plateforme digitale MACIFJuris est réservé aux sociétaires, particuliers personnes physiques, détenteurs d’un contrat d’assurance IARD (ex.: Auto et Habitation) et /ou adhérents d’un contrat santé ou prévoyance à titre individuel assuré par APIVIA Macif Mutuelle souscrit(s) auprès de la Macif. La plateforme MACIFJuris est éditée par INTER MUTUELLES ASSISTANCE TECHNOLOGIES, SAS au capital de 500.000 €, RCS NANTES 353 259 922, Siège social : 31 Route de Gachet CS 60833 44308 NANTES cedex 3."
      },
    ],
  },
  {
    id: "carton",
    title: "Le carton",
    room: "chambre",
    scenario: ["change-cap"],
    content: [
      {
        type: "text",
        text: "Bravo ! Un carton c’est très utile, surtout pour un déménagement. Et ce n’est pas le seul objet qui peut vous rendre service dans cette situation. Rendez-vous dans la cuisine pour trouver la checklist !",
      },
    ],
    redirectObject: {
      room: "cuisine",
      id: "checklist",
      title: "La checklist",
    },
  },
  {
    id: "cv",
    title: "Le CV",
    room: "salon",
    scenario: ["change-cap"],
    content: [
      {
        type: "text",
        text: "Bien vu, vous avez trouvé le CV ! <br> Saviez-vous que 36% des français sont insatisfaits de leur travail ? Parfois changer de job c'est nécessaire mais c'est aussi courageux. Et quand on doit refaire son CV ce n'est pas toujours évident de savoir quoi mettre dedans. Justement : rendez-vous dans le bureau, on vous aide à y voir plus clair.",
      },
    ],
    redirectObject: {
      room: "bureau",
      id: "ordinateur-cv",
      title: "Ordinateur",
    },
  },
  {
    id: "tablet",
    title: "La tablette",
    room: "salon",
    scenario: ["change-cap"],
    content: [
      {
        type: "text",
        text: `Super ! Saviez-vous que 49% des Français (non retraités) mettent déjà de l’argent de côté pour compléter leur retraite ou comptent le faire ?*Cliquez sur le dossier "préparer ma retraite" pour en savoir plus. Que vous souhaitiez faire une simulation, écouter des témoignages ou bénéficier de conseils d'experts, on met à votre disposition toutes les infos utiles en fin d'expérience !”.`,
      },
      {
        type: "ressource",
        text: `Le guide retraite`,
        label: `Le guide retraite`,
        url: "https://www.macif.fr/assurance/particuliers/les-moments-cles/je-prepare-demain/guide-retraite/telecharger-mon-guide-retraite",
      },
      {
        type: "ressource",
        text: `Le simulateur de retraite`,
        label: `Le simulateur de retraite`,
        url: "https://www.macif.fr/assurance/particuliers/banque-credit-epargne/simulateur-retraite",
      },
      {
        type: "text",
        text: `*Sondage Odoxa Les Français et la retraite : des "fourmis" angoissées et mal informées - 29/09/21. Entre l’immatriculation, les déclarations, la domiciliation, le choix du statut, il faut penser à tout ! Et s’il existait un espace où tout est prévu pour répondre à vos questions sur la création d’entreprise ?`,
      },
    ],
  },
  {
    id: "boiteoutils",
    title: "Boîte à outils",
    room: "garage",
    scenario: ["soin-maison"],
    content: [
      {
        type: "text",
        text: "Vous êtes imbattables ! Pour vos envies de rénovation, rafraîchissement, décoration de votre logement, avec Macif Service Travaux, bénéficiez : <br>- d’une estimation gratuite en ligne de vos travaux. <br>- d’une mise en relation avec des artisans qualifiés et référencés Macif<br>- d’une vérification de la cohérence des devis",
      },
      {
        type: "ressource",
        text: `MACIF Service Travaux`,
        label: `MACIF Service Travaux`,
        url: "https://www.macif.fr/assurance/particuliers/assurance-habitation-et-vie-quotidienne/service-travaux",
        cg: "Soumis à conditions et réservé aux sociétaires de la Macif, personnes physiques. Service de mise en relation avec des professionnels qualifiés en vue de la réalisation d’embellissements ou de petits travaux dans leur logement. Macif Service Travaux est un service IMA Services, SASU au capital de 3 553 329 € . RCS Niort 430 336 552. Siège social : 118, avenue de Paris 79033 Niort cedex 09. Ce service est mis en oeuvre par Inter Mutuelles Habitat, GIE au capital de 100 000 €. RCS Niort 494 671 795. Siège social : 471b rue du Puits Japie CS 10007 79410 ECHIRE.",
      },
    ],
  },
  {
    id: "papierpeint",
    title: "Le papier peint",
    room: "garage",
    scenario: ["soin-maison"],
    content: [
      {
        type: "text",
        text: "Vous avez l’oeil !<br/>Tri, désencombrement, méthode feng shui ou AIBI : voici quelques méthodes pour aménager votre intérieur sans envisager de travaux. <br/>Retrouvez plus de détails ici.",
      },
      {
        type: "ressource",
        text: `Article - Aménager son intérieur`,
        label: `Article - Aménager son intérieur`,
        url: "https://vousparmacif.macif.fr/amenager-interieur-logement",
      },
    ],
  },
  {
    id: "rangement",
    title: "Le rangement",
    room: "salon",
    scenario: ["soin-maison"],
    content: [
      {
        type: "text",
        text: "Un grand bravo ! Se sentir bien chez soi, ça passe aussi par de petits changements comme une maison bien organisée et qui respire. Justement : respirez 1 min le temps de découvrir nos 4 conseils de pro en vidéo pour bien organiser votre intérieur. <br/>Direction le bureau, vous y serez plus à l'aise pour la regarder.",
      },
    ],
    redirectObject: {
      room: "bureau",
      id: "ordinateur-rangement",
      title: "Ordinateur",
    },
  },
  {
    id: "telephone",
    title: "Le téléphone",
    room: "salon",
    scenario: ["soin-maison"],
    content: [
      {
        type: "text",
        text: `Clés perdues, fuite d'eau, panne de chaudière... Savez-vous que votre contrat Habitation Macif comprend une assistance pour un dépannage d'urgence ? Dans les conditions fixées au contrat, une aide est apportée immédiatement en cas d'imprévu nécessitant une intervention urgente avec la prise en charge du déplacement d'un prestataire et de la première heure de main d'oeuvre... Découvrez le détail dans le cas d'une perte de clé ...`,
      },
      {
        type: "ressource",
        text: `Assistance Habitation : perte de clé - chauffage`,
        label: `Assistance Habitation : perte de clé - chauffage`,
        url: "https://www.macif.fr/assurance/particuliers/conseils/vie-pratique/assurance-perte-de-cle",
        cg: "Contrat Habitation Résidence Principale ou contrat Multigarantie Vie Privée Responsabilité civile. Les garanties sont accordées dans les conditions et limites fixées aux contrats souscrits.",
      },
    ],
  },
  {
    id: "carte-assurance",
    title: "La carte",
    room: "bureau",
    scenario: ["change-horizon"],
    content: [
      {
        type: "text",
        text: "😊 Vous avez trouvé la carte européenne d’assurance maladie ! Savez-vous comment vous faire rembourser vos frais médicaux si vous avez besoin d’un médecin à l’étranger ?",
      },
      {
        type: "ressource",
        text: `FAQ - Actes médicaux à l'étranger`,
        label: `FAQ - Actes médicaux à l'étranger`,
        url: "https://www.macif.fr/assurance/particuliers/faq?question=remboursez-vous-actes-medicaux-letranger",
      },
    ],
  },
  {
    id: "lunettes",
    title: "Les lunettes",
    room: "cuisine",
    scenario: ["change-horizon"],
    content: [
      {
        type: "text",
        text: "Félicitations ! Vous avez découvert les lunettes de soleil ! Vacances sportives, détente en clubs ou découverte de nouveaux horizons : retrouvez toutes vos offres voyages jusqu'à 70% de réduction.",
      },
      {
        type: "ressource",
        text: `Offre - Macif avantages voyage`,
        label: `Offre - Macif avantages voyage`,
        url: "https://www.macifavantages.fr/voyages-locations/#category=macif-all&page=1",
        cg: "Offres Macif Avantages soumises à conditions et non rétroactives, réservées aux sociétaires de la Macif, hors personnes morales. Les offres Macif Avantages sont proposées par la société M.A&S, SAS au capital de 37 000 €. RCS Niort 509 462 636. Siège social : 1 rue Jacques Vandier 79000 Niort. Immatriculée au Registre des Opérateurs de Voyages et de Séjours Atout France, sous le numéro IM079110011."
      },
    ],
  },
  {
    id: "checklist",
    title: "La checklist",
    room: "cuisine",
    scenario: ["change-cap"],
    content: [
      {
        type: "text",
        text: "Vous l'avez trouvée ! Rien de plus pratique qu'une checklist pour penser à toutes les démarches comme l'autorisation de stationnement ou les ouvertures de compteurs. Pour vous simplifier la tâche, on a listé toutes les principales formalités à effectuer. On vous montre ?",
      },
      {
        type: "ressource",
        text: "Checklist - Déménagement",
        label: "Checklist - Déménagement",
        url: "https://www.macif.fr/files/live/sites/maciffr/files/maciffr/Particuliers/Momentscles/Demenagement/CHECKLIST_LOCATAIRE.pdf",
      },
    ],
  },
  {
    id: "ordinateur-cv",
    title: "Ordinateur",
    room: "bureau",
    scenario: ["change-cap"],
    content: [
      {
        type: "text",
        text: "On a tous des compétences à mettre en avant dans un CV mais savez-vous que 60% des recruteurs disent privilégier les compétences comportementales aux compétences techniques ? Si vous ne savez pas trop comment identifier vos compétences comportementales, Jobready est fait pour vous. Pour en savoir plus, vous pouvez mettre le lien dans votre pan",
      },
      {
        type: "ressource",
        text: "Page - compétences comportementales Jobready",
        label: "Page - compétences comportementales Jobready",
        url: "https://www.macif.fr/assurance/particuliers/les-moments-cles/entree-dans-la-vie-active/te-former/jobready",
        cg: "Jobready est un programme porté par l'association Article 1 (fusion des associations Frateli et Passeport Avenir), association d'intérêt général située au 29 Boulevard Bourdon, 75004 PARIS.",
      },
    ],
  },
  {
    id: "fontaine",
    title: "La fontaine zen",
    room: "bureau",
    scenario: ["change-horizon"],
    content: [
      {
        type: "text",
        text: `Super, vous avez trouvé la fontaine zen ! Plusieurs contrats Macif (Auto, Garantie Accident par exemple) prévoient une assistance aux personnes en déplacement en cas d’accident ou de maladie. Macif Assistance intervient 24h/24 partout en France et à l'étranger (rapatriement, avance sur frais médicaux, d'hospitalisation à l'étranger,...), pour porter secours à l’assuré, son conjoint et ses enfants.`,
      },
      {
        type: "ressource",
        text: `Page - Assistance à l'étranger`,
        label: `Page - Assistance à l'étranger`,
        url: "https://www.macif.fr/assurance/particuliers/faq?question=puis-je-souscrire-assurance-voyage",
        cg: "Les garanties d'assistance sont accordées dans les conditions et limites du contrat souscrit."
      },
    ],
  },
  {
    id: "ordinateur-rangement",
    title: "Ordinateur",
    room: "bureau",
    scenario: ["soin-maison"],
    content: [
      {
        type: "text",
        text: "Installez-vous confortablement et ouvrez grands les yeux",
      },
      {
        type: "youtube",
        urlIframe:
          "https://www.youtube.com/embed/NKAGq1CbRoQ?si=XZ3PC0np27q8w7is",
      },
      {
        type: "ressource",
        text: "Vidéo - Organiser son intérieur",
        label: "Vidéo - Organiser son intérieur",
        url: "https://www.youtube.com/watch?v=NKAGq1CbRoQ&t=5s",
      },
    ],
  },
];
